<template>
  <div class="billDetail-container">
    <Top></Top>
    <Fix></Fix>
    <div class="billDetail-container-top">
      <div>
        <div class="billDetail-div1">
          <img :src="require('/static/images/person-top.png')" alt="">
        </div>
        <div class="billDetail-div2"></div>
        <div class="billDetail-div3">城市代理人</div>
      </div>
    </div>
    <div class="billDetail-center">
        <div class="billDetail-center-title">
            <span>城市代理人详情</span>
        </div>
        <div class="billDetail-middle">
            <div>
                <div>
                    <span></span><span>城市代理人手机号码</span>
                </div>
                <div class="billDetail-phone">{{this.$route.query.telephone}}</div>
            </div>
            <div>
                <div class="billDetail-infor">
                    <span></span><span>对账信息</span>
                </div>
                <div>
                    <div class="billDetail-infor-title">
                        <div>结算期</div>
                        <div>发生（申报）时间</div>
                        <div>结算订单数</div>
                        <div>结算订单金额</div>
                        <div>结算佣金</div>
                    </div>
                    <div class="billDetail-infor-title2">
                        <div>{{this.$route.query.time1}}</div>
                        <div>{{this.$route.query.time2}}</div>
                        <div>{{this.$route.query.num}}</div>
                        <div>{{this.$route.query.amt}}</div>
                        <div>{{this.$route.query.income}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="billDetail-line"></div>
        <div class="billDetail-nextInfo">
            <div class="billDetail-next">
                <span></span><span>下级代理人</span>
            </div>
            <div class="billDetail-list">
                <div class="billDetail-list-title">
                    <div>手机号</div>
                    <div>上级手机号</div>
                    <div>结算期</div>
                    <div>发生（申报）时间</div>
                    <div>结算订单数</div>
                    <div>结算订单金额</div>
                    <div>结算佣金</div>
                    <div>操作</div>
                </div>
                <div>
                    <div v-for="(v,k) in list" :key="k" class="billDetail-list-eve">
                        <div>{{v.currentUserTel}}</div>
                        <div>{{v.perviousUserTel}}</div>
                        <div>{{v.yearmonth}}</div>
                        <div>{{v.updateTime}}</div>
                        <div>{{v.statementOrderNum}}</div>
                        <div>{{v.statementOrderAmt}}</div>
                        <div>{{v.statementIncome}}</div>
                        <div><span @click="detail(v.cpimsCode)">查看详情</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="billDetail-line"></div>
        <div class="billDetail-order">
            <div class="billDetail-next">
                <span></span><span>展业订单</span>
            </div>
            <div class="billDetail-orderList">
                <div class="billDetail-orderList-title">
                    <div>订单编号</div>
                    <div>商品名称</div>
                    <div>支付金额</div>
                    <div>支付时间</div>
                    <div>申报时间</div>
                </div>
                <div>
                    <div v-for="(v,k) in orderList" :key="k" class="billDetail-orderList-eve">
                        <div :title="v.orderCode">{{v.orderCode}}</div>
                        <div>{{v.goodsName}}</div>
                        <div>{{v.payAmt}}</div>
                        <div>{{v.payTime}}</div>
                        <div>{{v.declareTime}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '@/components/ui/top.vue';
import Fix from '@/components/ui/fix.vue';
import Foot from '@/components/ui/foot.vue';
export default {
  name: 'billDetail',
  components: {
    Top,
    Foot,
    Fix
  },
  data() {
      return {
        list:[],
        orderList:[]
      };
    },
    methods: {
      getdata1(){
          this.$http.post(this.GLOBAL.new_url+'/cityproxy/admin/statement/nextCityProxys',{
              cpimsCode:this.$route.query.id
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                //   console.log(res)
              if(res.data.code == "200"){
                  this.list = res.data.data
                  this.list.map(x=>{
                      x.currentUserTel = x.currentUserTel.substr(0, 3) + '****' + x.currentUserTel.substr(7)
                      x.perviousUserTel = x.perviousUserTel.substr(0, 3) + '****' + x.perviousUserTel.substr(7)
                  })
              }
            })
      },
      getdata2(val){
          var id = '';
          if(val){
              id = val 
          }else{
              id = this.$route.query.id
          }
          this.$http.post(this.GLOBAL.new_url+'/cityproxy/admin/statement/incomelist',{
              cpimsCode:id
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                //   console.log(res)
              if(res.data.code == "200"){
                  this.orderList = res.data.data
              }
            })
      },
      detail(val){
          this.getdata2(val)
      }
  },
 
  mounted(){
    this.getdata1();
    this.getdata2();
  }
}

</script>

<style scoped>
.billDetail-container {
  background-color: #fafafa;
  z-index: 2;
  position: relative;
}
.billDetail-container-top{
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(231, 231, 231, 0.5);
  height: 96px;
  background: #ffffff;
  margin-bottom: 4px;
}
  .billDetail-container-top>div{
    width: 1000px;
    margin: 0 auto;
    height: 96px;
  }
  .billDetail-div1{
    width: 120px;
    height: 45px;
    margin-top: 33px;
    margin-right: 20px;
  }
  .billDetail-div1>img{
    margin-top: 3px;
  }
  .billDetail-div2{
    width: 2px;
    height: 36px;
    background-color: #EB5E00;
    margin: 30px 18px 0 0;
  }
  .billDetail-div3{
    font-size: 24px;
    margin-top: 31px;
    color: #EB5E00;
    cursor: pointer;
  }
  .billDetail-container-top>div>div{
    float: left;
  }
  .billDetail-center{
    width: 1100px;
    margin: 0 auto;
    background: #ffffff;
    padding: 50px 45px 0;
  }
  .billDetail-center-title{
      height: 32px;
      border-bottom: 1px solid #D8D8D8;
      font-size: 16px;
      margin-bottom: 25px;
  }
  .billDetail-center-title>span{
      margin-left: 10px;
  }
  .billDetail-middle{
      padding-left: 10px;
      margin-bottom: 30px;
  }
  .billDetail-middle>div>div>span,.billDetail-next>span{
      vertical-align: middle;
      margin-right: 10px;
      display: inline-block;
      font-size: 16px;
  }
  .billDetail-middle>div>div>span:nth-of-type(1),.billDetail-next>span:nth-of-type(1){
      width: 4px;
      height: 14px;
      background: #EB5E00;
  }
  .billDetail-phone{
      margin: 10px 0 22px;
  }
  .billDetail-infor{
      margin-bottom: 20px;
  }
  .billDetail-infor-title,.billDetail-infor-title2{
      height: 22px;
      margin-bottom: 13px;
  }
  .billDetail-infor-title>div,.billDetail-infor-title2>div{
      float: left;
      width: 100px;
      text-align: center;
      margin-right: 40px;
  }
  .billDetail-infor-title>div:nth-of-type(2),.billDetail-infor-title2>div:nth-of-type(2){
      width: 175px;
  }
  .billDetail-infor-title2>div{
      font-size: 14px;
  }
  .billDetail-nextInfo{
      padding-left: 10px;
      margin-bottom: 30px;
  }
  .billDetail-line{
      border:1px solid #D8D8D8;
      margin-bottom: 24px;
  }
  .billDetail-list{
      border:1px solid #EEECEC;
      border-bottom: none;
      margin-top: 21px;
  }
  .billDetail-list-title{
      height: 53px;
      background: #F8F8F8;
  }
  .billDetail-list-title>div{
      float: left;
      height: 53px;
      line-height: 53px;
      text-align: center;
      width: 130px;
  }
  .billDetail-list-title>div:nth-of-type(4),.billDetail-list-eve>div:nth-of-type(4){
      width: 178px;
  }
  .billDetail-list-eve{
      height: 59px;
      border-bottom:1px solid #EEECEC;
  }
  .billDetail-list-eve>div{
      height: 59px;
      float: left;
      font-size: 14px;
      line-height: 59px;
      text-align: center;
      width: 130px;
  }
  .billDetail-list-eve>div>span{
      color: #EB5E00;
      text-decoration: underline;
      cursor: pointer;
  }
  .billDetail-order{
      margin-bottom: 60px;
      padding-left: 10px;
      padding-bottom: 45px;
  }
  .billDetail-orderList{
      margin: 32px 0 0;
      border:1px solid #EEECEC;
      border-bottom: none;
  }
  .billDetail-orderList-title{
      height: 53px;
      background: #F8F8F8;
  }
  .billDetail-orderList-title>div{
      float: left;
      height: 53px;
      line-height: 53px;
      text-align: center;
      width: 217.6px;
  }
  .billDetail-orderList-eve{
      height: 59px;
      border-bottom:1px solid #EEECEC;
  }
  .billDetail-orderList-eve>div{
      float: left;
      font-size: 14px;
      height: 59px;
      line-height: 59px;
      text-align: center;
      width: 217.6px;
  }
  .billDetail-orderList-eve>div:nth-of-type(1){
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
</style>

